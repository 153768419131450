import * as React from 'react'

import iconFamily from 'assets/icons/family.svg'
import iconAnnuity from 'assets/icons/annuity.svg'
import iconDisability from 'assets/icons/disability.svg'
import iconCare from 'assets/icons/care.svg'
import iconSaving from 'assets/icons/saving.svg'
import iconMedicare from 'assets/icons/medicare.svg'
import iconLifeSettlements from 'assets/icons/life-settlements.svg'

import iconAB from 'assets/icons/a-b.svg'
import iconCity from 'assets/icons/city.svg'
import iconCompass from 'assets/icons/compass.svg'
import iconFlag from 'assets/icons/flag.svg'
import iconMountain from 'assets/icons/mountain.svg'
import iconRoad from 'assets/icons/road.svg'

function resolveIcon(name) {
  switch(name) {
    case 'products/life-insurance':
    case 'family':
        return iconFamily

    case 'products/annuities':
    case 'annuity':
        return iconAnnuity

    case 'products/disability-income-insurance':
    case 'disability':
        return iconDisability

    case 'products/long-term-health-care-insurance':
    case 'care':
        return iconCare

    case 'products/401k':
    case 'saving':
        return iconSaving

    case 'products/medicare':
        return iconMedicare

    case 'products/life-settlements':
        return iconLifeSettlements

    case 'a-b':       return iconAB
    case 'city':      return iconCity
    case 'compass':   return iconCompass
    case 'flag':      return iconFlag
    case 'mountain':  return iconMountain
    case 'road':      return iconRoad

    default: return
  }
}

const Icon = ({ name, ...props }) => (
  <img src={resolveIcon(name)} loading="false" alt="" {...props} />
)

export default Icon

import * as React from 'react'
import * as css from './QuickLinks.module.css'
import { graphql, Link, useStaticQuery } from 'gatsby'
import Icon from 'components/common/Icon'
import { MdKeyboardArrowRight } from 'react-icons/md'

const QuickLink = ({ title, seo }) => (
  <Link className={css.link} to={`/${seo.slug.current}`}>
    <Icon className={css.icon} name={seo.slug.current} />
    {title}
    <MdKeyboardArrowRight className="desktop-and-up" />
  </Link>
)

const QuickLinks = ({ children }) => {
  const { allSanityProductPg } = useStaticQuery(query)

  return (
    <section className="container padding-x padding-y">
      {children && (
        <header className={`${css.header} richtext`}>
          {children}
        </header>
      )}

      <nav className={`${css.list} no-scrollbar`}>
        {allSanityProductPg.nodes.map((pg, key) => (
          <QuickLink {...pg} key={key} />
        ))}
      </nav>
    </section>
  )
}

export default QuickLinks

const query = graphql`query QuickLinks {
  allSanityProductPg(
    sort: {fields: order}
    filter: {title: {ne: "Advanced Markets"}}
  ) {
    nodes {
      title
      seo {
        slug {
          current
        }
      }
    }
  }
}`
